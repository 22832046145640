<template>
  <div class="d-flex mt-3 align-center">
    <VFileInput
      :key="`file-${fileKey}`"
      ref="fileUpload"
      v-model="file"
      class="d-none"
      accept="text/csv,.xls,.xlsx,.ods"
      type="file"
    />
    <TTBtn
      fab
      elevation="1"
      color="white"
      large
      :loading="loading"
      data-test-value="upload-file-btn"
      @click="handleClick"
    >
      <VIcon>fal fa-plus</VIcon>
    </TTBtn>
    <div class="d-flex flex-column ml-3">
      <span class="tt-text-body-2 tt-light-mono-46--text">
        {{ $tc('import.upload.file-uploading', uploadedFileCount) }}
      </span>
      <span
        class="tt-text-body-2 tt-light-mono-46--text"
      >
        {{ uploadCountText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    uploadByType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      file: null,
      fileKey: 0,
    };
  },
  computed: {
    uploadedFileCount() {
      return this.uploadByType.filter((file) => file.state).length;
    },
    uploadCountText() {
      return this.uploadByType.length ? this.$t('import.upload.upload-more-csv') : this.$t('import.upload.upload-csv');
    },
  },
  watch: {
    file(file) {
      if (file) {
        this.$emit('submit', file);
        this.fileKey += 1;
      }
    },
  },
  methods: {
    handleClick() {
      this.$refs.fileUpload.$refs.input.click();
    },
  },
};
</script>
