<template>
  <div class="d-flex flex-column my-3">
    <div
      v-if="file.id"
      class="file-card d-flex flex-column pa-3"
    >
      <div class="d-flex">
        <VBadge
          :value="!!validationBadge.icon"
          bordered
          :color="validationBadge.color"
          class="custom-badge"
          :icon="validationBadge.icon"
          offset-x="16"
          offset-y="16"
          overlap
        >
          <VAvatar color="tt-light-mono-4">
            <VIcon
              size="19"
              color="tt-light-mono-100"
            >
              fal fa-file
            </VIcon>
          </VAvatar>
        </VBadge>
        <div class="d-flex flex-column ml-3 mr-5 flex-grow-1">
          <div
            :class="{'font-weight-bold' : !isUploading}"
            class="tt-text-body-2"
          >
            {{ file.filename }}
          </div>
          <div class="d-flex flex-column">
            <VProgressLinear
              v-if="isUploading || isValidating"
              class="my-1"
              :height="4"
              :value="file.progress"
              :indeterminate="isValidating"
              color="tt-light-blue vibrant"
            />
            <div class="d-flex flex-column tt-text-body-2">
              <span
                v-if="isSuccess"
                class="tt-light-green--text vibrant"
              >
                {{ $t('import.upload.success') }}
              </span>
              <span
                v-if="isFailure && file.error"
                class="tt-light-red--text vibrant"
              >{{ file.error }}
              </span>
              <span
                v-if="isWarning && file.warning"
                class="tt-light-yellow--text vibrant"
              >{{ file.warning }}
              </span>
            </div>
          </div>
          <div
            v-if="isUploading && !isValidating"
            class="tt-text-body-2 tt-light-mono-46--text"
          >
            {{ $t('import.upload.completed',{ n : file.progress } ) }}
          </div>
          <div
            v-if="isValidating"
            class="tt-text-body-2 tt-light-mono-46--text"
          >
            {{ $t('import.upload.file_validation' ) }}
          </div>
        </div>
        <VBtn
          icon
          x-small
          class="my-3"
          :loading="loading"
          :disabled="isValidating"
          color="tt-light-blue"
          data-test-value="import-delete-file"
        >
          <VIcon
            size="19"
            @click="handleDelete"
          >
            fal fa-times
          </VIcon>
        </VBtn>
      </div>
      <div
        v-if="!isValidating && !isSuccess && !isUploading"
        class="file-card-bottom pa-3 mt-3"
      >
        <template v-if="isWarning">
          <span class="tt-text-body-2">
            {{ $t("import.upload.warning") }}
            <a
              v-if="file.hasReport && reportsFeatureIsActive"
              :data-test-label="testLabelType(file.type)"
              data-test-value="report"
              class="tt-text-body-2 text-decoration-none"
              @click="handleOpenReportDialog"
            >
              {{ $t("import.upload.download-report") }}
            </a>
          </span>
          <TTCheckbox
            v-model="checked"
            class="upload-checkbox mt-2 pt-0"
            data-test-value="import-confirm-warning"
            @change="$emit('checked', checked)"
          >
            <template #label>
              <span class="tt-text-body-2">
                {{ $t('import.upload.checked') }}
              </span>
            </template>
          </TTCheckbox>
        </template>
        <template v-if="isFailure && !file.reportFileUrl">
          <span class="tt-text-body-2">
            {{ $t("import.upload.failure_no_report") }}
            <a
              class="tt-text-body-2 text-decoration-none"
              :href="$t('import.prepare-file-link')"
            >
              {{ $t("import.prepare-file-short") }}
            </a>
          </span>
        </template>
        <template v-if="isFailure && file.reportFileUrl">
          <span class="tt-text-body-2">
            {{ $t("import.upload.failure_with_report") }}
            <a
              v-if="file.hasReport && reportsFeatureIsActive"
              :data-test-label="testLabelType(file.type)"
              data-test-value="report"
              class="tt-text-body-2 text-decoration-none"
              @click="handleOpenReportDialog"
            >
              {{ $t("import.upload.failure_report_link") }}
            </a>
            {{ $t("import.upload.and") }}
            <a
              class="tt-text-body-2 text-decoration-none"
              :href="$t('import.prepare-file-link')"
            >
              {{ $t("import.prepare-file-short") }}
            </a>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { IMPORT_FILE_STATE } from './constants';

export default {
  name: 'FileCard',

  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      checked: false,
    };
  },

  computed: {
    isUploading() {
      return !this.file.state;
    },
    isValidating() {
      return this.file.state === IMPORT_FILE_STATE.VALIDATION || this.file.progress >= 100;
    },
    isSuccess() {
      return this.file.state === IMPORT_FILE_STATE.VALIDATION_SUCCESS;
    },
    isWarning() {
      return this.file.state === IMPORT_FILE_STATE.VALIDATION_WARNING;
    },
    isFailure() {
      return this.file.state === IMPORT_FILE_STATE.VALIDATION_FAILURE;
    },
    reportsFeatureIsActive() {
      return this.$di.ff.get('import_reports');
    },
    validationBadge() {
      if (this.isSuccess) {
        return {
          icon: 'fas fa-check',
          color: 'tt-light-green vibrant',
        };
      }
      if (this.isWarning) {
        return {
          icon: 'fas fa-exclamation',
          color: 'tt-light-yellow vibrant',
        };
      }
      if (this.isFailure) {
        return {
          icon: 'fas fa-exclamation',
          color: 'tt-light-red vibrant',
        };
      }
      return {};
    },
  },

  methods: {
    testLabelType(type) {
      switch (type) {
        case 'teams':
          return 'team';
        case 'staff_positions':
          return 'sp';
        default:
          return 'user';
      }
    },
    handleDelete() {
      this.loading = true;
      this.$emit('delete', this.file.id);
      setTimeout(() => { this.loading = false; }, 1000);
    },
    handleOpenReportDialog() {
      this.$emit('open-report', this.file.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-card {
  border: 1px solid map-get($tt-light-mono-4, 'base');
  border-radius: 8px;
}
.file-card-bottom {
  background: map-get($tt-dark-mono-96, 'base');
  border-radius: 4px;
}
::v-deep {
  .container {
    padding: 0;
  }

  .upload-checkbox {
    i {
      background: map-get($tt-light-mono-0, 'base');
    }
  }

  .custom-badge {
    z-index: 3;
    padding: 0;
    line-height: 1.5;

    .v-icon {
      font-size: 10px;
    }
  }
}
</style>
