<template>
  <TTView>
    <VRow>
      <VCol
        cols="12"
        md="9"
      >
        <span
          class="tt-text-headline-1 tt-light-mono-100--text"
          data-test-value="import-title"
        >
          {{ $t("import.title") }}
        </span>
      </VCol>
      <VCol
        cols="12"
        md="9"
        class="pt-1 pb-0"
      >
        <span
          class="tt-text-body-1"
          data-test-value="import-subtitle"
        >{{ $t('import.upload.description') }}</span>
      </VCol>
      <VCol
        cols="12"
        md="9"
        class="pt-2 pb-3"
      >
        <a
          class="tt-text-body-1 text-decoration-none"
          data-test-value="import-help-link"
          :href="$t('import.prepare-file-link')"
          target="_blank"
        >
          {{ $t("import.prepare-file") }}
        </a>
        <span class="tt-light-mono-46--text tt-light-mono-4 tt-text-body-2 px-2 py-1 ml-1 rounded-xl">PDF</span>
      </VCol>

      <VCol
        v-for="section in sections"
        :key="section.type"
        class="py-0 mt-3"
        cols="12"
        md="9"
      >
        <div class="d-flex">
          <VIcon
            size="17"
            color="tt-light-blue"
          >
            {{ section.icon }}
          </VIcon>
          <span
            class="ml-2 tt-text-subtitle"
            :data-test-value="`import-title-${section.type}`"
          >{{ $t(`import.upload.${section.type}`) }}</span>
        </div>
        <div class="d-flex mt-2 mb-4">
          <a
            class="tt-text-body-1 text-decoration-none"
            :data-test-value="`download-file-template-${section.type}`"
            :href="$t(`import.upload.prepare-file-${section.type}`)"
            target="_blank"
          >
            {{ $t('import.upload.prepare-file-csv') }}
          </a>
        </div>
        <FileUpload
          :loading="section.loading"
          :upload-by-type="uploadByType(section.type)"
          :data-test-value="`upload-file-${section.type}`"
          @submit="handleUploadFile($event, section.type)"
        />
        <div class="d-flex flex-column-reverse my-4">
          <FileCard
            v-for="file in uploadByType(section.type)"
            :key="file.id"
            :file="file"
            date-test="import-file-card"
            :data-test-value="section.type"
            @delete="deleteFile($event)"
            @checked="checkedFile($event, file.id)"
            @open-report="openReportDialog($event)"
          />
        </div>
      </VCol>
      <VCol
        cols="12"
        md="11"
        class="mb-sm-16 mb-md-0"
      >
        <VDivider />
        <div class="d-flex align-center py-3">
          <div class="d-flex flex-column">
            <span
              v-if="fullImport || partlyImport"
              class="tt-text-body-1"
            >{{ $tc("import.upload.import-count", fullImport + partlyImport) }}</span>
            <span class="tt-text-body-2 tt-light-mono-46--text">
              <span v-if="fullImport">{{ fullImport }} {{ $t('import.upload.full') }}</span>
              <span v-if="fullImport && partlyImport">, </span>
              <span v-if="partlyImport">{{ partlyImport }} {{ $t('import.upload.partly') }}</span>
            </span>
          </div>
          <VSpacer />
          <TTBtn
            large
            :disabled="isInvalid"
            :loading="runLoading"
            data-test-value="import-btn"
            @click="runImport"
          >
            {{ $t('import.upload.import') }}
          </TTBtn>
        </div>
      </VCol>
    </VRow>

    <template #left>
      <VRow>
        <VCol class="d-flex justify-start justify-md-center">
          <div class="d-flex flex-column align-center">
            <TTBtn
              :to="{ name : Names.R_ACCOUNT_V2_COMPANY_IMPORT_INDEX }"
              fab
              elevation="1"
              color="white"
              large
              data-test="tt-btn"
              data-test-value="import-back-btn"
            >
              <VIcon>fal fa-arrow-left</VIcon>
            </TTBtn>
            <span class="tt-text-footnote tt-light-mono-46--text mt-2">
              {{ $t('import.upload.back') }}
            </span>
          </div>
        </VCol>
      </VRow>
    </template>

    <ErrorReportingPopup
      v-model="isShowReportDialog"
      type="upload"
      @download="downloadReport"
    />
  </TTView>
</template>

<script>
import axios from 'axios';

import { TransportPolling } from '@front.backoffice/common';

import {
  VALID_STATES, TYPES, FILES_STATES, IMPORT_FILE_STATE, IMPORT_STATE,
} from '../../components/import/constants';
import FileUpload from '../../components/import/FileUpload.vue';
import FileCard from '../../components/import/FileCard.vue';
import ErrorReportingPopup from '../../components/data/ErrorReportingPopup.vue';

export default {
  name: 'ImportUpload',

  components: { FileCard, FileUpload, ErrorReportingPopup },

  inject: ['Names'],

  props: {
    importId: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      runLoading: false,
      isShowReportDialog: false,

      sections: [
        {
          type: 'teams',
          icon: 'fa-solid fa-folder-tree',
        },
        {
          type: 'staff_positions',
          icon: 'fa-solid fa-user-tie',
        },
        {
          type: 'users',
          icon: 'fa-solid fa-users',
        },
      ],

      files: [],
      newFiles: [],
      checked: [],
      reportImportId: null,

      poll: null,
    };
  },

  computed: {
    uploadByType() {
      return (type) => this.files.filter((file) => file.type === TYPES[type]);
    },

    isInvalid() {
      if (!this.files.length) {
        return true;
      }
      return !!this.files.find(
        (file) => !file.state
          || !VALID_STATES.includes(file.state)
          || (file.state === IMPORT_FILE_STATE.VALIDATION_WARNING && !this.checked.includes(file.id)),
      );
    },

    fullImport() {
      if (!this.files.length) {
        return 0;
      }
      return this.files.filter(
        (file) => file.state === IMPORT_FILE_STATE.VALIDATION_SUCCESS,
      ).length;
    },

    partlyImport() {
      if (!this.files.length) {
        return 0;
      }
      return this.files.filter(
        (file) => (file.state === IMPORT_FILE_STATE.VALIDATION_WARNING && this.checked.includes(file.id)),
      ).length;
    },
  },

  async created() {
    await this.getImport();
  },

  beforeDestroy() {
    this.stopPolling();
  },

  methods: {
    openReportDialog(fileId) {
      this.reportImportId = fileId;
      this.isShowReportDialog = true;
    },

    downloadReport() {
      const { reportFileUrl } = this.files.find((el) => el.id === this.reportImportId);
      window.open(reportFileUrl, '_blank');
      this.isShowReportDialog = false;
    },

    addReportUrlToFiles() {
      const newItems = [];
      this.files.forEach(async (item) => {
        const newItem = {
          ...item,
          reportFileUrl: await this.getReportFileUrl(item),
        };

        newItems.push(newItem);
      });

      this.files = newItems;
    },

    async getReportFileUrl(file) {
      if (!file.hasReport) return '';
      let reportFileUrl = '';
      try {
        const { url } = await this.$di.api.FileToStream.getReportImportFileUrl(
          { companyId: this.companyId, importFileId: file.id },
        );
        reportFileUrl = url;
      } catch (e) {
        console.warn(e);
      }
      return reportFileUrl;
    },

    async pollingData() {
      this.poll = new TransportPolling(this.getImport, {
        delay: 2000,
        retryLimit: 50,
      });
      this.poll.start();
    },

    stopPolling() {
      if (this.poll) {
        this.poll.stop();
      }
    },

    checkedFile(bool, id) {
      if (bool) {
        this.checked.push(id);
      } else {
        this.checked = this.checked.filter((item) => item !== id);
      }
    },

    async getImport() {
      try {
        const { files, state } = await this.$di.api.FileToStream.getImport(
          { companyId: this.companyId, importId: this.importId },
        );
        if (state !== IMPORT_STATE.INITIAL) {
          await this.$router.push(
            { name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_PROCESSING, params: { importId: this.importId } },
          );
        } else if (this.files.length && files.length) {
          this.files = [
            ...this.files.filter((file) => !files.find((fileFromServe) => fileFromServe.id === file.id)),
            ...files,
          ];
        } else {
          this.files = files;
        }
        this.files.sort((a, b) => {
          if (!a.state || a.id < b.id) {
            return -1;
          }
          if (!b.state || a.id > b.id) {
            return 1;
          }
          return 0;
        });

        this.addReportUrlToFiles();
      } catch (e) {
        this.$di.notify.errorHandler(e);
        await this.$router.push(
          { name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_INDEX, params: { importId: this.importId } },
        );
      } finally {
        const stop = !this.files.find((file) => file.state && !FILES_STATES.includes(file.state));
        if (stop) {
          this.stopPolling();
        }
      }
    },

    async handleUploadFile(file, type) {
      let fileId = null;
      try {
        const { id, url } = await this.$di.api.FileToStream.prepareUrl(
          { companyId: this.companyId, importId: this.importId },
        );
        fileId = id;
        const cancelTokenSource = axios.CancelToken.source();
        const fileObject = {
          id,
          progress: 0,
          type,
          filename: file.name,
          stop: cancelTokenSource.cancel,
        };
        this.files.push(fileObject);
        const config = {
          onUploadProgress: (progressEvent) => {
            fileObject.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
          cancelToken: cancelTokenSource.token,
          headers: {
            'Content-Type': file.type,
          },
        };
        await axios.put(url, file, config);
        const { importFileId } = await this.$di.api.FileToStream.addImportFile({
          companyId: this.companyId,
          importId: this.importId,
          fileId: id,
          filename: file.name,
          type: TYPES[type],
        });
        fileObject.id = importFileId;
        await this.pollingData();
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.$di.notify.errorHandler(this.$t('import.upload.upload-error'));
        }
        if (fileId) {
          this.files = this.files.filter((f) => f.id !== fileId);
        }
      }
    },

    async deleteFile(id) {
      const file = this.files.find((f) => f.id === id);
      try {
        if (file.progress) {
          file.stop();
        } else if (file.state) {
          await this.$di.api.FileToStream.removeImportFile(
            { companyId: this.companyId, importId: this.importId, importFileId: file.id },
          );
        }
        this.files = this.files.filter((f) => f.id !== file.id);
        await this.getImport();
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },

    async runImport() {
      this.runLoading = true;
      try {
        await this.$di.api.FileToStream.runImport(
          { companyId: this.companyId, importId: this.importId, resolveFiles: this.files.map((file) => file.id) },
        );
        await this.$router.push(
          { name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_PROCESSING, params: { importId: this.importId } },
        );
      } catch (e) {
        this.$di.notify.errorHandler(e);
      } finally {
        this.runLoading = false;
      }
    },
  },

};
</script>
